import { Button } from '@mantine/core';
import { signIn } from 'next-auth/react';
import React, { FunctionComponent } from 'react';

interface IProps {
  callbackUrl?: string;
}

export const SocialLoginButtons: FunctionComponent<IProps> = ({
  callbackUrl,
}) => {
  return (
    <>
      <Button
        fullWidth={true}
        color="blue"
        onClick={() => signIn('facebook', { callbackUrl })}
      >
        Login mit Facebook
      </Button>
      <Button
        fullWidth={true}
        color="red"
        onClick={() => signIn('google', { callbackUrl })}
      >
        Login mit Google
      </Button>
      {process.env.NODE_ENV === 'development' && (
        <Button
          fullWidth={true}
          color="dark"
          onClick={() => signIn('github', { callbackUrl })}
        >
          Login mit GitHub
        </Button>
      )}
    </>
  );
};
