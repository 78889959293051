import React, { FunctionComponent } from 'react';
import { FieldError as FieldErrorType } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IProps {
  error: FieldErrorType;
}

export const FieldError: FunctionComponent<IProps> = ({ error }) => {
  const { t } = useTranslation('common');
  return <>{error.message || t(`validation.${error.type}`)}</>;
};
