import { Button, PasswordInput, SimpleGrid, TextInput } from '@mantine/core';
import { signIn } from 'next-auth/react';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';

import { FieldError } from '@components/Form/FieldError';

interface ICredentials {
  email: string;
  password: string;
}

interface IProps {
  callbackUrl?: string;
}

export const CredentialsLoginForm: FunctionComponent<IProps> = ({
  callbackUrl,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICredentials>();

  const onSubmit = async ({ email, password }: ICredentials) => {
    signIn('credentials', {
      email,
      password,
      callbackUrl,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SimpleGrid>
        <TextInput
          type="email"
          label="E-Mail"
          {...register('email', { required: true })}
          error={errors.email && <FieldError error={errors.email} />}
        />
        <PasswordInput
          label="Passwort"
          {...register('password', { required: true })}
          error={errors.password && <FieldError error={errors.password} />}
        />
        <Button type="submit" fullWidth={true} color="red">
          Mit E-Mail + Passwort anmelden
        </Button>
      </SimpleGrid>
    </form>
  );
};
