import { Center, Divider, SimpleGrid, Text } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { CredentialsLoginForm } from '@components/Auth/CredentialsLoginForm';
import { SocialLoginButtons } from '@components/Auth/SocialLoginButtons';
import { Card } from '@components/Ui/Card';

export const LoginForm: FunctionComponent = () => {
  const callbackUrl = `${process.env.NEXT_PUBLIC_BASE_URL}/api/auth/checkInvite`;

  return (
    <Center
      sx={(theme) => ({
        minHeight: '100vh',
        backgroundColor: theme.colors.dark,
      })}
    >
      <Card padding="xl" radius="lg">
        <SimpleGrid>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src="/images/bcup-logo-dark.svg" alt="Logo" />
          <Text size="sm" color="dimmed" align="center">
            Bitte melde dich mit deinem Account an
          </Text>
          <SocialLoginButtons callbackUrl={callbackUrl} />
          <Divider label="oder" labelPosition="center" />
          <CredentialsLoginForm callbackUrl={callbackUrl} />
        </SimpleGrid>
      </Card>
    </Center>
  );
};
