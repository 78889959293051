import {
  Box,
  Card as MantineCard,
  CardProps,
  MantineColor,
  Text,
} from '@mantine/core';
import React, { FunctionComponent } from 'react';

interface IProps extends CardProps<'div'> {
  title?: string;
  subtitle?: string;
  color?: MantineColor;
}

export const Card: FunctionComponent<IProps> = ({
  title,
  subtitle,
  color,
  children,
  ...props
}) => {
  return (
    <MantineCard
      shadow="sm"
      padding="md"
      {...props}
      sx={(theme) => color && { backgroundColor: theme.colors[color][3] }}
    >
      {(title || subtitle) && (
        <Box mb="md">
          {title && (
            <Text size="lg" weight={500}>
              {title}
            </Text>
          )}
          {subtitle && (
            <Text size="sm" color="dimmed">
              {subtitle}
            </Text>
          )}
        </Box>
      )}
      {children}
    </MantineCard>
  );
};
